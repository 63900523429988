import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const PartyDetail = () => {
  const { id } = useParams();

  // 유저 에이전트로 기기 판별
  const userAgent = navigator.userAgent.toLowerCase();
  const isAndroid = userAgent.includes("android");
  const isIOS =
    userAgent.includes("iphone") ||
    userAgent.includes("ipad") ||
    userAgent.includes("ipod");

  // Play Store 및 App Store 링크
  const androidStoreUrl =
    "https://play.google.com/store/apps/details?id=com.dayvent.app.dayvent_app_client";
  // const iosStoreUrl = 'https://apps.apple.com/kr/app/dayvent/id123456789';

  if (isAndroid) {
    window.location.href = androidStoreUrl;
    return null;
  }

  // if (isIOS) {
  //   window.location.href = iosStoreUrl;
  //   return null;
  // }

  return (
    <Container>
      <GlassContainer>
        <LogoImage src="/assets/dayventlogo.png" alt="Dayvent Logo" />
        <Title>데이벤트와 함께하는 특별한 순간</Title>
        <Message>스토어로 이동하고 있습니다...</Message>
        <LoadingSpinner />
      </GlassContainer>
    </Container>
  );
};

export default PartyDetail;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #4d4d4d, #808080);
  animation: gradientBG 15s ease infinite;

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const GlassContainer = styled.div`
  padding: 3rem;
  background: rgba(128, 128, 128, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(77, 77, 77, 0.37);
  border: 1px solid rgba(192, 192, 192, 0.12);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const LogoImage = styled.img`
  width: 120px;
  margin-bottom: 1.5rem;
`;

const Title = styled.h1`
  color: #e8e8e8;
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(64, 64, 64, 0.3);
`;

const Message = styled.p`
  color: #e8e8e8;
  font-size: 1.3rem;
  margin: 0;
  opacity: 0.85;
  letter-spacing: 0.5px;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  margin: 2rem auto 0;
  border: 4px solid rgba(192, 192, 192, 0.2);
  border-radius: 50%;
  border-top: 4px solid #e8e8e8;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
