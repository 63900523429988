import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";

const Logo = () => {
  useEffect(() => {
    gsap.to("#heartS", {
      y: -10,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 2,
    });
    gsap.to("#heartM", {
      y: -5,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 2.5,
    });
    gsap.to("#heartL", {
      y: -15,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 1,
    });
    gsap.to("#textbox1", {
      y: 4,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 1.2,
    });
    gsap.to("#textbox2", {
      y: -7,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 0.8,
    });
    gsap.to("#textbox3", {
      y: -6,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 1,
    });
    gsap.to("#textbox4", {
      y: -12,
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      duration: 1.2,
    });
  }, []);

  return (
    <Container>
      <LogoImg src="/assets/MobileLogo.png" />
      <HeartS id="heartS" src="/assets/heart_1.png" />
      <HeartM id="heartM" src="/assets/heart_2.png" />
      <HeartL id="heartL" src="/assets/heart_3.png" />
      <BoxSection>
        <TextBox id="textbox1" margin="30px">
          <Text>오늘 뭐하지?</Text>
        </TextBox>
        <TextBoxEmpty id="textbox2" margin="12px">
          <Text>최고의 파티 장소, 여기 어때?</Text>
        </TextBoxEmpty>
        <TextBoxEmpty id="textbox3" margin="45px">
          <Text>이곳에서 파티 멋지지 않을까?</Text>
        </TextBoxEmpty>
        <TextBox id="textbox4" margin="26px">
          <Text>인플루언서와 함께해 볼까?</Text>
        </TextBox>
      </BoxSection>
    </Container>
  );
};

export default Logo;

const Container = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  width: 120px;
`;

const HeartS = styled.img`
  position: absolute;
  top: 0;
  left: 180px;
`;

const HeartM = styled.img`
  position: absolute;

  right: 380px;
`;

const HeartL = styled.img`
  position: absolute;
  top: 20px;
  right: 160px;
`;

const Text = styled.div`
  font-size: 16px;
`;

const BoxSection = styled.div`
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 2px solid #2c086b;
  margin-left: ${(props) => props.margin || "0"};
  margin-right: ${(props) => props.margin || "0"};
  margin-top: 30px;

  background: linear-gradient(
    120deg,
    rgba(145, 117, 192, 0.3),
    rgba(255, 182, 193, 0.7)
  );
  border-radius: 20px;
  padding: 8px 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`;

const TextBoxEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 40px;
  border: 2px solid #2c086b;
  color: #111;
  margin-left: ${(props) => props.margin || "0"};
  margin-right: ${(props) => props.margin || "0"};
  margin-top: 30px;
  background: transparent;
  border-radius: 20px;
  padding: 8px 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`;
