import React from "react";
import ReactDOM from "react-dom/client";
import MainPage from "./MainPage";
import { Reset } from "styled-reset";
import Footer from "./components/Footer";
import Router from "./Router";
import GlobalStyle from "./globalStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Reset />
    <MainPage /> */}
    <GlobalStyle />
    <Router />
  </React.StrictMode>
);
