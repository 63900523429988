import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  /* background-color: #1a1a1a */

  }
  body {
    margin : 0;



  }
  a {
    color: inherit;
    text-decoration: none;
  }
  `;

export default GlobalStyle;
