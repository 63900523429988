import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PartyDetail from "./page/PartyDetail";
import MainPage from "./MainPage";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}></Route>
        <Route path="/partyDetail/:id" element={<PartyDetail />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
      {/* <ReactQueryDevtools initialIsOpen={true} position="bottom-right" /> */}
    </BrowserRouter>
  );
}

function Layout(children) {
  return <>{children}</>;
}

export default Router;
